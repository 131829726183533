import { SHARE_DATA_VERSION } from '@constants';

import { Action, ActionType } from '@store/interfaces/share';

const initialState: any = {
  users: [],
  stores: [],
  all_status_label: {},
  currentDispatcher: null,
  dispatchers: [],
  countries: [],
  metadata: {},
  ticketConfig: { environment: null, api_production: null, api_sandbox: null },
  aiSearchConfig: { api_base_url: null },
};

export default function share(state = initialState, action: Action) {
  const { payload } = action;
  switch (action.type) {
    case ActionType.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
        metadata: {
          ...state.metadata,
          countries: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.SET_CURRENT_DISPATCHER:
      return {
        ...state,
        currentDispatcher: payload,
      };
    case ActionType.GET_ALL_STORE_SUCCESS:
      return {
        ...state,
        stores: payload,
        metadata: {
          ...state.metadata,
          stores: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_DISPATCHERS_SUCCESS:
      return {
        ...state,
        dispatchers: payload,
        metadata: {
          ...state.metadata,
          dispatchers: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_ALL_STATUS_LABEL_SUCCESS:
      return {
        ...state,
        all_status_label: payload,
        metadata: {
          ...state.metadata,
          all_status_label: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        users: payload,
        metadata: {
          ...state.metadata,
          users: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_TICKET_CONFIG_REQUEST:
      return {
        ...state,
        ticketConfig: {
          ...(state.ticketConfig || {}),
          loading: true,
        },
      };
    case ActionType.GET_TICKET_CONFIG_REQUEST_SUCCESS:
      return {
        ...state,
        ticketConfig: {
          ...payload,
          loading: false,
        },
        metadata: {
          ...state.metadata,
          ticketConfig: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_AISEARCH_CONFIG_REQUEST:
      return {
        ...state,
        aisearchConfig: {
          ...(state.aisearchConfig || {}),
          loading: true,
        },
      };
    case ActionType.GET_AISEARCH_CONFIG_REQUEST_SUCCESS:
      return {
        ...state,
        aisearchConfig: {
          ...payload,
          loading: false,
        },
        metadata: {
          ...state.metadata,
          aisearchConfig: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.CLEAR_SHARE_DATA:
      return {
        users: [],
        stores: [],
        countries: [],
        metadata: {},
        ticketConfig: {},
        aiSearchConfig: {},
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
