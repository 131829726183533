export enum PERMISSION {
  ADMIN = 'Magento_Backend::all',
  DASHBOARD = 'OpenTechiz_DashboardReports::report_return',
  REPORT_OVERDUE_DELIVERY_DATE = 'OpenTechiz_Shipout::report_overdue_delivery_date',
  REPORT_LATE = 'OpenTechiz_Logistic::report_late',
  TEAM = 'OpenTechiz_ReturnManagement::return_management',
  FRESHDESK = 'OpenTechiz_FreshdeskTicket::freshdesk_ticket',
  IMAGE_SEARCH = 'OpenTechiz_ImageSearch::imagesearch',
  BACKOFFICE = 'OpenTechiz_AdminSSO::afterShip',
  SHIPMENT_ITEM_ARRIVED = 'OpenTechiz_Shipout::manage_items_arrived',
  SHIPMENT_BATCH_PACKAGE = 'OpenTechiz_Shipout::manage_shipout_packages',
  SHIPMENT_ORDER_PENDDING = 'OpenTechiz_Shipout::manage_shipout_packages',
  SHIPMENT_ORDER_WAIT_CARRIER = 'OpenTechiz_Dispatcher::package_ready_ship',
  SHIPMENT_ORDER_SHIPPED = 'OpenTechiz_Dispatcher::dispatcher',
  SHIPMENT_BATCH_PACKAGE_SHIPMENT = 'OpenTechiz_Shipout::manage_items_arrived',
  SHIPMENT_SENT_TO_DISPATCHER = 'OpenTechiz_Shipout::manage_items_arrived',
  SHIPMENT_ARRIVED_TO_DISPATCHER = 'OpenTechiz_Shipout::manage_shipped_out_items',
  RETURN_MANAGER = 'OpenTechiz_ReturnManagement::manage_return',
  RETURN_MANAGEMENT = 'OpenTechiz_ReturnManagement::return_management',
  RETURN_MANAGE_REFUND = 'OpenTechiz_ReturnManagement::manage_return',
  RETURN_MANAGE_PROCESS_ITEM = 'OpenTechiz_ReturnManagement::process_return',
  RETURN_SENT_TO_HQ = 'OpenTechiz_ReturnManagement::manage_return',
  RETURN_ARRIVED_TO_HQ = 'OpenTechiz_ReturnManagement::manage_return',
  RETURN_SENT_TO_DISPATCHER = 'OpenTechiz_ReturnManagement::manage_return',
  RETURN_BATCH_PACKAGE = 'OpenTechiz_ReturnManagement::manage_return',
  AFTERSHIP = 'OpenTechiz_ReturnManagement::manage_return',
}
