import { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AppLayout } from '@layouts/AppLayout';
import { BaseLayout } from '@layouts/BaseLayout';
import { RootError } from '@layouts/RootError';

import AuthGuard from './AuthGuard';

const dynamicImport = (component: string) => lazy(() => import(`@pages/${component}`));

const Login = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));
const ForgotPassword = dynamicImport('ForgotPassword');

const Dashboard = lazy(() => import('@pages/Dashboard'));
const OAuthCallback = lazy(() => import('@pages/OAuthCallback'));

const ShipmentBatchPackage = lazy(() => import('@pages/Shipment/BatchPackage'));
const ShipmentSentDispatcher = lazy(() => import('@pages/Shipment/SentDispatcher'));
const ShipmentArriveDispatcher = lazy(() => import('@pages/Shipment/ArriveDispatcher'));
const ShipoutWaiting = lazy(() => import('@pages/Shipment/ShipoutWaiting'));
const CreateShipoutPackage = lazy(() => import('@pages/Shipment/CreateShipoutPackage'));
const ShipmentOrdersPending = lazy(() => import('@pages/Shipment/OrdersPending'));
const ShipmentOrdersWaitCarrierShip = lazy(() => import('@pages/Shipment/OrdersWaitCarrierShip'));
const ShipmentOrdersShipped = lazy(() => import('@pages/Shipment/OrdersShipped'));
const BatchPackageList = lazy(() => import('@pages/Shipment/BatchPackageList'));
const PackageEdit = lazy(() => import('@pages/Shipment/PackageEdit'));
const SupportTicket = lazy(() => import('@pages/SupportTicket'));
const ImageSearchHistory = lazy(() => import('@pages/ImageSearch/History/History'));

const ReturnsBatchPackageShipment = lazy(() => import('@pages/Returns/BatchPackageShipment'));
const ReturnsSentHQ = lazy(() => import('@pages/Returns/SentHQ'));
const ReturnsArrivedHQ = lazy(() => import('@pages/Returns/ArrivedHQ'));
const ReturnsSentDispatcher = lazy(() => import('@pages/Returns/SentDispatcher'));
const ReturnsRefund = lazy(() => import('@pages/Returns/Refund'));
const ReturnsProcessItem = lazy(() => import('@pages/Returns/ProcessItem'));
const ReturnRequestItems = lazy(() => import('@pages/Returns/RequestItems'));
const ReturnRequestItemForm = lazy(() => import('@pages/Returns/RequestItems/EditForm'));
const RefundRequestItemForm = lazy(() => import('@pages/Returns/Refund/EditForm'));

const AftershipDashboard = lazy(() => import('@pages/AfterShip/Dashboard'));
const AftershipManageShipments = lazy(() => import('@pages/AfterShip/ManageShipments'));
const AftershipReportShipments = lazy(() => import('@pages/AfterShip/Reports/ReportShipments'));
const AftershipReportExceptions = lazy(() => import('@pages/AfterShip/Reports/ReportExceptions'));
const AftershipReportCourierDestination = lazy(() => import('@pages/AfterShip/Reports/ReportCourierDestination'));

const LatestOrdersReport = lazy(() => import('@pages/Reports/LatestOrdersReport'));
const ShipmentOverdueDelivery = lazy(() => import('@pages/Reports/ShipmentOverdueDelivery'));
const Members = lazy(() => import('@pages/Members'));
const DownloadReturnCodePdf = lazy(() => import('@pages/Returns/DownloadReturnCodePdf/DownloadReturnCodePdf'));
const BulkDownloadReturnCodePdf = lazy(() => import('@pages/Returns/BulkDownloadReturnCodePdf/BulkDownloadReturnCodePdf'));

export const routes = [
  {
    path: '',
    element: <BaseLayout />,
    errorElement: <RootError />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'oauth-callback', element: <OAuthCallback /> },
      { path: 'register', element: <SignUp /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <AppLayout />
      </AuthGuard>
    ),
    errorElement: <RootError />,
    breadcrumb: 'Home',
    props: {
      icon: 'home',
    },
    children: [
      { index: true, element: <Navigate to="dashboard" replace /> },
      { path: 'dashboard', element: <Dashboard />, breadcrumb: 'Dashboard' },
      {
        path: 'shipments',
        breadcrumb: 'Shipments',
        children: [
          { index: true, element: <Navigate to="/shipments/batch-package" replace /> },
          { path: 'shipout-manage-itemsarrived', element: <ShipoutWaiting />, breadcrumb: 'Manage Items Arrived' },
          {
            path: 'batch-package-list',
            breadcrumb: 'Batch Package List',
            children: [
              { index: true, element: <BatchPackageList /> },
              {
                path: 'edit',
                breadcrumb: 'Edit Package',
                element: <PackageEdit />,
              },
            ],
          },
          { path: 'orders-pending', element: <ShipmentOrdersPending />, breadcrumb: 'Orders - Pending Shipment' },
          { path: 'orders-wait-carrier-ship', element: <ShipmentOrdersWaitCarrierShip />, breadcrumb: 'Orders - Wait for carrier' },
          { path: 'orders-shipped', element: <ShipmentOrdersShipped />, breadcrumb: 'Orders - Shipped' },
          {
            path: 'batch-package',
            breadcrumb: 'Batch Order Package Process',
            children: [
              { index: true, element: <ShipmentBatchPackage /> },
              { path: 'sent-to-dispatcher', element: <ShipmentSentDispatcher />, breadcrumb: 'Sent to Dispatcher' },
              { path: 'arrive-to-dispatcher', element: <ShipmentArriveDispatcher />, breadcrumb: 'Arrive To Dispatcher' },
            ],
          },
          { path: 'package/add', element: <CreateShipoutPackage />, breadcrumb: 'Create Shipout Package' },
        ],
      },
      {
        path: 'returns',
        breadcrumb: 'Returns',
        children: [
          { index: true, element: <Navigate to="/returns/request-items" replace /> },
          {
            path: 'request-items',
            breadcrumb: 'Return List',
            children: [
              { index: true, element: <ReturnRequestItems /> },
              { path: 'edit/?', element: <ReturnRequestItemForm />, breadcrumb: 'Edit' },
            ],
          },
          {
            path: 'refund-items',
            breadcrumb: 'Refund List',
            children: [
              { index: true, element: <ReturnsRefund /> },
              { path: 'edit/?', element: <RefundRequestItemForm />, breadcrumb: 'Edit' },
            ],
          },
          { path: 'process-item', element: <ReturnsProcessItem />, breadcrumb: 'Process Item' },
          {
            path: 'batch-package-shipment',
            breadcrumb: 'Batch Package Shipment',
            children: [
              { index: true, element: <ReturnsBatchPackageShipment /> },
              { path: 'sent-to-hq', element: <ReturnsSentHQ />, breadcrumb: 'Sent To HQ' },
              { path: 'arrive-to-hq', element: <ReturnsArrivedHQ />, breadcrumb: 'Arrived to HQ' },
              { path: 'sent-to-dispatcher', element: <ReturnsSentDispatcher />, breadcrumb: 'Sent to Dispatcher' },
            ],
          },
          { path: 'download-return-code-pdf', element: <DownloadReturnCodePdf />, breadcrumb: 'Download Return Code PDF' },
          { path: 'bulk-download-return-code-pdf', element: <BulkDownloadReturnCodePdf />, breadcrumb: 'Bulk Download Return Code PDF' },
        ],
      },
      {
        path: 'aftership',
        breadcrumb: 'Aftership',
        children: [
          { index: true, element: <AftershipDashboard /> },
          { path: 'manage-shipments', element: <AftershipManageShipments />, breadcrumb: 'Manage Shipment' },
          {
            path: 'reports',
            breadcrumb: 'Reports',
            children: [
              { index: true, element: <Navigate to="/aftership/reports/shipments" replace />, breadcrumb: '' },
              { path: 'shipments', element: <AftershipReportShipments />, breadcrumb: 'Report Shipments' },
              { path: 'exceptions', element: <AftershipReportExceptions />, breadcrumb: 'Report Exceptions' },
              { path: 'courier-and-destination', element: <AftershipReportCourierDestination />, breadcrumb: 'Report Courier and Destination' },
            ],
          },
        ],
      },
      {
        path: 'report',
        breadcrumb: 'Report',
        children: [
          { path: 'shipment-overdue-delivery', breadcrumb: 'Overdue Delivery Date', element: <ShipmentOverdueDelivery /> },
          { path: 'late-item', breadcrumb: 'Late Report', element: <LatestOrdersReport /> },
          //
        ],
      },
      { path: 'members', element: <Members />, breadcrumb: 'Team' },
      {
        path: 'ticket',
        breadcrumb: 'Ticket',
        children: [
          { path: 'support-ticket', breadcrumb: 'Support Ticket', element: <SupportTicket /> },
          //
        ],
      },
      {
        path: 'imagesearch',
        breadcrumb: 'Image Search',
        children: [
          { path: 'history', breadcrumb: 'Image Search History', element: <ImageSearchHistory /> },
          //
        ],
      },
    ],
  },
];
/**
 * Application routes
 * https://reactrouter.com/en/main/routers/create-browser-router
 */
export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL,
});

// monitor route change
// router.subscribe((state) => {
// const history = createBrowserHistory();
// useEffect(() => {
//   const unlisten = history.listen((location: any, action: any) => {
//     console.log('Route changed', { location, action });
//     // Apply route change logic, i.e. dispatch to store
//   });
//   return unlisten;
// }, []);
// clear
// console.log(state);
// });

export const breadcrumb = routes;
